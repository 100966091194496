import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import ContentContainer from "../components/ContentContainer";

const Cookies = ({ pageContext }) => (
	<Layout pageContext={pageContext}  >
		<SEO
			title={pageContext.COOKIES_META_TITLE}
			description={pageContext.COOKIES_META_DESCRIPTION}
			lang={pageContext.lang}
		/>
		<ContentContainer>
			<h1>{pageContext.COOKIES_HEADLINE}</h1>
			<p>
				{pageContext.COOKIES_BLOCK_1}
				{pageContext.COOKIES_BLOCK_2}
				{pageContext.COOKIES_BLOCK_3}
			</p>
			<p>{pageContext.COOKIES_BLOCK_4}</p>
			<p>{pageContext.COOKIES_BLOCK_5}</p>
			<p>
				<strong>{pageContext.COOKIES_BLOCK_6}</strong>
			</p>
			<p>{pageContext.COOKIES_BLOCK_7}</p>
			<ul>
				<li>{pageContext.COOKIES_BLOCK_8}</li>
				<li>{pageContext.COOKIES_BLOCK_9}</li>
			</ul>
			<p>{pageContext.COOKIES_BLOCK_10}</p>
			<ul>
				<li>{pageContext.COOKIES_BLOCK_11}</li>
				<li>{pageContext.COOKIES_BLOCK_12}</li>
				<li>{pageContext.COOKIES_BLOCK_13}</li>
				<li>{pageContext.COOKIES_BLOCK_14}</li>
				<li>{pageContext.COOKIES_BLOCK_15}</li>
				<li>{pageContext.COOKIES_BLOCK_16}</li>
				<li>{pageContext.COOKIES_BLOCK_17}</li>
				<li>{pageContext.COOKIES_BLOCK_18}</li>
				<li>{pageContext.COOKIES_BLOCK_19}</li>
			</ul>
			<p>{pageContext.COOKIES_BLOCK_20}</p>
			<p>
				{pageContext.COOKIES_BLOCK_21}
				{pageContext.COOKIES_BLOCK_22}
				{pageContext.COOKIES_BLOCK_23}
			</p>
			<p>
				<strong>{pageContext.COOKIES_BLOCK_24}</strong>
			</p>
			<p>{pageContext.COOKIES_BLOCK_25}</p>
			<p>
				<strong>{pageContext.COOKIES_BLOCK_26}</strong>
			</p>
			<p>{pageContext.COOKIES_BLOCK_27}</p>
			<p>{pageContext.COOKIES_BLOCK_28}</p>
			<p>{pageContext.COOKIES_BLOCK_29}</p>
			<p>
				<strong>{pageContext.COOKIES_BLOCK_30}</strong>
			</p>
			<p>{pageContext.COOKIES_BLOCK_31}</p>
			<ul>
				<li>{pageContext.COOKIES_BLOCK_32}</li>
				<li>{pageContext.COOKIES_BLOCK_33}</li>
			</ul>
			<p>{pageContext.COOKIES_BLOCK_34}</p>
			<ul>
				<li>
					<a href="https://policies.google.com/technologies/cookies?hl=cs">
						https://policies.google.com/technologies/cookies?hl=cs
					</a>
				</li>
				<li>
					<a href="https://www.facebook.com/policy.php">
						https://www.facebook.com/policy.php
					</a>
				</li>
			</ul>
		</ContentContainer>
	</Layout>
);

Cookies.propTypes = {
	pageContext: PropTypes.objectOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.bool,
			PropTypes.object,
			PropTypes.array
		])
	).isRequired
};

export default Cookies;
