import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Div = styled.div`
	background: #f4f4f4;
	.row-content {
		margin: auto;
		padding: 100px 30px;
		max-width: 2300px;
	}
	h1 {
		margin: 50px 0;
	}
	p {
		margin-bottom: 20px;
		text-align: justify;
		line-height: 20px;
	}

	ul {
		margin: 0 0 1.5em;
	}
	ul a {
		color: #161616;
	}
`;

const ContentContainer = ({ children, className }) => (
	<Div className={`content-row-container light ${className}`}>
		<div className="row-content">{children}</div>
	</Div>
);

ContentContainer.defaultProps = {
	className: ""
};

ContentContainer.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

export default ContentContainer;
